import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalLink from "../components/external-link"

const IndexPage = () => (
  <Layout
    title="Democratic Socialism for the many"
    color="red"
    heroImageName="index"
    layoutClassName="home"
  >
    <SEO title="Bread and Roses DSA" ogImage="home" titleTemplate={`%s`} />
    <div className="content">
      <p>
        Bread & Roses is a national caucus of Marxist organizers in the{" "}
        <ExternalLink href="https://www.dsausa.org/">
          Democratic Socialists of America
        </ExternalLink>
        . We are committed to helping build an effective DSA rooted in the
        multiracial working class.
      </p>

      <p>
        From the return of strikes and the great wave of rank-and-file led
        reform sweeping unions like the{" "}
        <ExternalLink href="https://socialistcall.com/2022/12/02/uaw-members-united-reform-results/">
          United Auto Workers
        </ExternalLink>{" "}
        and the{" "}
        <ExternalLink href="https://socialistcall.com/2021/12/04/union-democracy-teamsters-uaw-socialists/">
          Teamsters
        </ExternalLink>
        , to democratic socialist victories at the ballot box, independent class
        politics is back. Now is the time to make{" "}
        <ExternalLink href="https://socialistcall.com/2018/09/26/what-is-democratic-socialism/">
          democratic socialism
        </ExternalLink>{" "}
        a fundamental part of U.S. society. And building a real democratic
        socialist alternative to the Republican and Democratic Parties is our
        best hope to avoid total climate catastrophe and to defeat the far
        right, racism, anti-feminist and anti-queer politics, imperialism, war,
        and neoliberal capitalism.
      </p>

      <p>
        Bread & Roses wants to work with all democratic socialists to build such
        an alternative. One with a{" "}
        <ExternalLink href="https://jacobin.com/2019/03/rank-and-file-strategy-union-organizing">
          militant, left-wing, and democratic
        </ExternalLink>{" "}
        labor movement at its core. An alternative that can fight and win
        Medicare for All and a Green New Deal, fight for equality and liberation
        for all oppressed people, and end the Democratic Party&rsquo;s chokehold
        on progressive politics.
      </p>

      <p>
        The Democratic Socialists of America is a key part of that movement. We
        want to help DSA become a vehicle for working people to effectively take
        on and beat the billionaire class. This will require organizing local
        and national struggles that can inspire and radicalize more and more
        people and eventually win tangible gains for millions. In this process,
        we must also promote a compelling vision and strategy for democratic
        socialist transformation. As a big tent organization in a country with a
        wide range of political contexts, the specifics of what this looks like
        will vary from place to place. But the common thread is{" "}
        <ExternalLink href="https://socialistcall.com/2018/08/16/liberalism-ultraleftism-or-mass-action/">
          mass politics and mass action
        </ExternalLink>
        . Without such an approach, DSA will continue to struggle to root itself
        in the multiracial working class.
      </p>

      <p>
        Bread & Roses&rsquo;s politics and perspectives are outlined in our{" "}
        <ExternalLink href="https://breadandrosesdsa.org/where-we-stand/">
          Where We Stand
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink href="https://breadandrosesdsa.org/tasks-2022-23/">
          Tasks for 2022-2023
        </ExternalLink>{" "}
        documents. We strongly encourage active DSA members who agree with our
        point of view and strategy to consider{" "}
        <ExternalLink href="https://breadandrosesdsa.org/join/">
          filling out this form
        </ExternalLink>{" "}
        to learn more about joining B&R.
      </p>
    </div>
  </Layout>
)

export default IndexPage
